import * as actionTypes from '../constants/action-types';

const initialState = {
  showMovieInfo: false,
  showTVInfo: false,
  movieID: 0,
  TVID: 0,
  movie: '',
  tv: '',
  credits: {},
  video: {},
  blocked: {},
  movieSQL: '',
  tvSeriesEpisodes: {},
  bookmarks: []
};



export const movies_redux = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SHOW_MOVIEINFO:
      //console.log('ShowMovie: true ');
      return Object.assign({}, state, {
        showMovieInfo: true,
      });

    case actionTypes.SHOW_TVINFO:
      return Object.assign({}, state, {
        showTVInfo: true,
      });

    case actionTypes.CLOSE_MOVIEINFO:
      return Object.assign({}, state, {
        showMovieInfo: false,
      });

    case actionTypes.CLOSE_TVINFO:
      return Object.assign({}, state, {
        
        showTVInfo: false,
      });

    case actionTypes.SET_MOVIEID:
      return Object.assign({}, state, {
        movieID: action.payload,
      });

    case actionTypes.SET_TVID:
      return Object.assign({}, state, {
        TVID: action.payload,
      });

    case actionTypes.SET_MOVIEINFO:
      return Object.assign({}, state, {
        movie: action.payload,
      });

    case actionTypes.SET_TVINFO:
      return Object.assign({}, state, {
        tv: action.payload,
      });

    case actionTypes.SET_MOVIESQLINFO:
      return Object.assign({}, state, {
        movieSQL: action.payload,
      });

    case actionTypes.SET_CREDITSINFO:
      return Object.assign({}, state, {
        credits: action.payload,
      });

    case actionTypes.SET_VIDEOINFO:
      return Object.assign({}, state, {
        video: action.payload,
      });

    case actionTypes.SET_BLOCKEDINFO:
      return Object.assign({}, state, {
        blocked: action.payload,
      });

    case actionTypes.SET_TVSERIESINFO:
      return Object.assign({}, state, {
        tvSeriesEpisodes: action.payload,
      });

    case actionTypes.ZERO_BOOKMARKTITLE:
      return Object.assign({}, state, {
        bookmarks: [],
      });
    case actionTypes.INIT_BOOKMARKTITLE:
    
      var newbookmarks = []
      const bookmarkdata = action.payload.map(item => { 
        newbookmarks.unshift({
          id: item.id,
          ...item.titleID
        })
      })
      // Make sure not more than 50 TITLES
      newbookmarks = newbookmarks.slice(0,50)
      //console.log ('INIT_BOOKMARKTITLE: ', newbookmarks)
      return Object.assign({}, state, {
        bookmarks: newbookmarks
      });


    /*case actionTypes.ADD_BOOKMARKTITLE:
      //console.log('Adding bookmark: ', state.bookmarks, action.payload)
      var newbookmarks = [...state.bookmarks]
      newbookmarks.unshift(action.payload)
      return Object.assign({}, state, {
        bookmarks: newbookmarks
      });*/



    case actionTypes.DEL_BOOKMARKTITLE:
      //console.log'Del bookmark: ', state.bookmarks, action.payload)
      var newbookmarks = [...state.bookmarks]
      newbookmarks.map( (title, index) => {
        //console.log'Del: ', index, title.id, action.payload)
        if (title.id == action.payload)
          newbookmarks.splice(index, 1)
      })
      //console.log'Del bookmark after: ', newbookmarks, action.payload)
      /*for (var i = 0; i < newbookmarks.length; i++) { if (newbookmarks[i] === action.payload) { newbookmarks.splice(i, 1); i--; } }*/
      return Object.assign({}, state, {
        bookmarks: newbookmarks
      })

    case actionTypes.ADD_BOOKMARKTITLE_SUCCESS:
      //console.log'Adding bookmark: ', state.bookmarks, action.payload)
      var newbookmarks = [...state.bookmarks]
      newbookmarks.unshift(action.payload)
      return Object.assign({}, state, {
        bookmarks: newbookmarks
      })
          
    
    default:
      return state;
  }
};
