import { put, call, takeEvery, takeLatest, fork } from 'redux-saga/effects';
import startup from './StartupSagas';
import {
  userAuthSagas,
  userFetchSagas,
  userLogout,
  userAuthWithProvider,
  updateUserProfile,
  userDBFetch,
  userRegister,
  updateUserPassword,
  userUpdateSuccess,
  userCountryFetch,
  userAddFilter,
  userDBCheckForFilter,
  userDBAdd,
  userRegisterWithProvider,
  bookmarkDBAdd,
  bookmarkDBDel,
  bookmarkDBInit,
  bookmarkDBZero
} from './userAuth';
import * as actionTypes from '../constants/action-types';

export default function* rootSaga() {
  //console.log('HelloSagas!')
  /*yield [
        takeLatest(actionTypes.STARTUP_REQUEST, startup),
        takeLatest(actionTypes.USER_LOGIN_REQUEST, userAuthSagas),
        takeLatest(actionTypes.USER_LOGIN_WITH_PROVIDER_REQUEST, userAuthWithProvider),
        takeLatest(actionTypes.USER_FETCH_REQUEST, userFetchSagas),
        takeLatest(actionTypes.USER_LOGOUT_REQUEST, userLogout),
        takeLatest(actionTypes.USER_UPDATE_REQUEST, updateUserProfile),
    ] */
  yield takeLatest(actionTypes.STARTUP_REQUEST, startup);
  yield takeLatest(actionTypes.USER_FETCH_REQUEST, userFetchSagas);
  yield takeLatest(actionTypes.USER_LOGIN_REQUEST, userAuthSagas);
  yield takeLatest(actionTypes.USER_LOGOUT_REQUEST, userLogout);

  yield takeLatest(actionTypes.USER_LOGIN_WITH_PROVIDER_REQUEST, userAuthWithProvider);
  
  yield takeLatest(actionTypes.REGISTER_FIREBASE_USER, userRegister);
  yield takeLatest(actionTypes.REGISTER_FIREBASE_USER_WITH_PROVIDER, userRegisterWithProvider);
  yield takeLatest(actionTypes.USER_REGISTER_SUCCESS, userDBAdd);
  yield takeLatest(actionTypes.USER_REGISTER_WITH_PROVIDER_SUCCESS, userDBAdd)
  yield takeLatest(actionTypes.USER_REGISTER_WITH_PROVIDER_FAILURE, userLogout);

  yield takeLatest(actionTypes.USER_FETCH_SUCCESS, userDBFetch);
  yield takeLatest(actionTypes.USER_LOGIN_SUCCESS, userDBFetch);
  yield takeLatest(actionTypes.USER_LOGIN_WITH_PROVIDER_SUCCESS, userDBFetch);
  
//yield takeLatest(actionTypes.USERDB_FETCH_SUCCESS, userDBCheckForFilter);

  yield takeLatest(actionTypes.USER_FETCH_FAILURE, userCountryFetch);
  yield takeLatest(actionTypes.USER_UPDATE_REQUEST, updateUserProfile);
  yield takeLatest(actionTypes.CHANGE_FIREBASE_USER_PASSWORD, updateUserPassword);

  yield takeLatest(actionTypes.USER_FILTER_ADD_REQUEST, userAddFilter);
  yield takeLatest(actionTypes.USERDB_ADD_SUCCESS, userAddFilter);

  yield takeLatest(actionTypes.USER_LOGOUT_SUCCESS, bookmarkDBZero);
  yield takeLatest(actionTypes.USERDB_FETCH_SUCCESS_WITH_FILTER, bookmarkDBInit);
  yield takeLatest(actionTypes.ADD_BOOKMARKTITLE, bookmarkDBAdd);
  yield takeLatest(actionTypes.DEL_BOOKMARKTITLE, bookmarkDBDel);
 
}
