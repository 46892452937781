import React from 'react';
import { BrowserRouter, Route, Link, Redirect, withRouter } from 'react-router-dom';
import {
  fetchUserRequest,
  userLogoutRequest,
  setMovieInfo,
  closeMovieInfo,
} from '../../redux/actions';
import './Footer.css';
import Facebook from '../../assets/Facebook.png';
import Twitter from '../../assets/Twitter.png';
import Youtube from '../../assets/Youtube.png';

const Footer = () => {
  return (
    <div style={{ width: '100%' }}>
      <div className="foot">
        <a
          style={{ textDecoration: 'none', color: '#222222' }}
          href={'https://www.facebook.com/3WiseApp/'}
          target="_blank"
        >
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <img style={{ maxWidth: '30px', maxHeight: '30px' }} src={Facebook} alt="loading..." />
            <p style={{ textDecoration: 'none', textTransform: 'none', color: '#222222' }}>
              {' '}
              Facebook{' '}
            </p>
          </div>
        </a>

        <a
          style={{ textDecoration: 'none', color: '#222222' }}
          href={'https://twitter.com/3wise3'}
          target="_blank"
        >
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <img style={{ maxWidth: '30px', maxHeight: '30px' }} src={Twitter} alt="loading..." />
            <p>Twitter</p>
          </div>
        </a>

        <a
          style={{ textDecoration: 'none', color: '#222222' }}
          href={'https://www.youtube.com/channel/UCHcnlqBwMvPqG6fLa6AE0yw'}
          target="_blank"
        >
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <img style={{ maxWidth: '30px', maxHeight: '30px' }} src={Youtube} alt="loading..." />
            <p>YouTube</p>
          </div>
        </a>
      </div>

      <div className="foot_copyright">
        <p>&copy; 2020 3Wise</p>
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  setMovieInfo: content => dispatch(setMovieInfo(content)),
  closeMovieInfo: () => dispatch(closeMovieInfo()),
});

export default withRouter(Footer);
