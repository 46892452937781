import React from 'react';
import List from './List';
import WrapListHistory from './ListHistory';
import WrapNetflixNew from './NetflixNew';
import { connect } from 'react-redux';
import store from '../../../redux/store/index';

import shortid from 'shortid';
import isEqual from "react-fast-compare";

const movies = {
  /*upcoming: {
    apiCall: "upcoming",
    header: "Upcoming"
  },
  topRated: {
    apiCall: "top_rated",
    header: "Top Rated"
  }, */
  action: {
    apiCall: 28,
    type: 'movie',
    title: 'action',
    header: 'Top ranked action movies matching filter',
  },
  actiontv: {
    apiCall: 28,
    type: 'tv',
    title: 'action',
    header: 'Top ranked action TV series matching filter',
  },
  /*adventure: {
    apiCall: 12,
    header: "Adventure"
  }, */
  /*animation: {
    apiCall: 16,
    title: "Animation",
    header: "TOP CLEAN ANIMATION MOVIES"
  },*/
  /*comedy: {
    apiCall: 35,
    type: 'movie',
    title: 'Comedy',
    header: 'Top ranked comedy movies matching filter',
  },

  comedytv: {
    apiCall: 28,
    type: 'tv',
    title: 'Comedy',
    header: 'Top ranked comedy TV series matching filter',
  },

  crime: {
    apiCall: 80,
    type: 'movie',
    title: 'Crime',
    header: 'Top ranked crime movies matching filter',
  },

  crimetv: {
    apiCall: 80,
    type: 'tv',
    title: 'Crime',
    header: 'Top ranked crime TV series matching filter',
  },

  drama: {
    apiCall: 18,
    type: 'movie',
    title: 'Drama',
    header: 'Top ranked drama movies matching filter',
  },

  dramatv: {
    apiCall: 18,
    type: 'tv',
    title: 'Drama',
    header: 'Top ranked drama TV series matching filter',
  },

  romance: {
    apiCall: 10749,
    type: 'movie',
    title: 'Romance',
    header: 'Top ranked romantic movies matching filter',
  },

  romancetv: {
    apiCall: 10800,
    type: 'tv',
    title: 'Romance',
    header: 'Top ranked romantic TV series matching filter',
  },

  documentary: {
    apiCall: 99,
    type: 'movie',
    title: 'Documentary',
    header: 'Top ranked documentaries matching filter',
  },*/
};


class WrapListsOfMovies extends React.Component {


 
  shouldComponentUpdate(nextProps) {
    //console.log'ListOfMovies: shouldUpdate: ', this.props, nextProps)
    return !isEqual(this.props, nextProps);
  }

 

  render() {
    const {filterChoices} = this.props;
    var type;
    var genres;
    if (filterChoices['showMovies']) {
      type = 'movie';
      genres = filterChoices['genres']
    }
    else {
      type= 'tv'
      genres = filterChoices['genres']
    }


    //console.log'ListOfMovies: filterChoices  ', this.props);
    return (
      <div>
      


      <List
        key={shortid.generate()}
        heading={'Admin Filter Result'}
        title={'Admin Filter Result'}
        genres={genres}
        type={type}
        filterChoices={this.props.filterChoices}
        movieShuffleSeed={this.props.movieShuffleSeed}
        region={this.props.region}
        
      />
        
      </div>
    );
  }
}


export default WrapListsOfMovies;

