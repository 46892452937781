import React from 'react';
import Button from '@material-ui/core/Button';
import {
  Link,
  DirectLink,
  Element,
  Events,
  animateScroll,
  scrollSpy,
  scroll,
  scroller,
} from 'react-scroll';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

import UpIcon from '@material-ui/icons/ArrowDropUp';
import DownIcon from '@material-ui/icons/ArrowDropDown';
import PlayIcon from '@material-ui/icons/PlayArrow';

import IconButton from '@material-ui/core/IconButton';

import './StopWatch.css';

const styles = theme => ({
  NormalItem: {
    color: 'grey',
  },
});

const StyledListItem = withStyles({
  root: {
    Color: 'grey',
    backgroundColor: 'rgb(160,160,160)',
    '&$selected': {
      backgroundColor: 'grey',
    },
  },
  selected: {},
})(ListItem);

class SubtitleDisplay extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    reachedEnd: false,
    Subtitles: {},
    timeSubtitles_subset: {},
    timeSubtitles: [],
    visible: false,
  };

  AdjT = pos => {
    /*var diff,newTimerStart;
        diff = this.state.timerTime - pos;
        newTimerStart = this.state.timerStart + diff;
        this.setState({timerStart: newTimerStart}) 
        //console.log('StopWatch, Got adjusted time:', pos, this.state.timerTime, this.state.timerStart ) */
    //console.log('StopWatch in SubtitleDisplay, Got adjusted time:', pos)
    this.props.OnTimeChange(pos);
  };

  ShiftT = pos => {
    this.props.OnTimeShift(pos);
  };

  StretchT = pos => {
    //console.log("SubtitleDisplay, StretchT: ", pos)
    this.props.OnTimeStretch(pos);
  };

  ScrollSubtitles = pos => {
    //scroll.scrollToBottom();
    //console.log('StopWatch, pos:', pos)
    scroller.scrollTo(pos, {
      containerId: 'scroll-container',
      offset: -100,
      duration: 300,
      smooth: 'easeInOutQuart',
    });
  };

  /* animateScroll.scrollTo(pos, {
        duration: 800,
        delay: 0,
        offset: -200,
        smooth: 'easeInOutQuart',
        containerId: 'scroll-container'
      })  */

  componentWillReceiveProps(nextProps, nextState) {
    var TList = [];
    var start;
    var end;
    var selectedE;
    //console.log('SubtitleDisplay received blocked subtitles_times: ', nextProps.subtitles_times)
    //console.log('SubtitleDisplay Current subtitles_times: ', this.state.timeSubtitles, this.state.timeSubtitles.length)
    //console.log('SubtitleDisplay received pos: ', nextProps.line)

    {
      /*if (this.state.subtitles_times != nextProps.subtitles_times) {
          TList = this.ExtractTimeStampsSubtitles(nextProps.subtitles);
          this.setState({timeSubtitles:TList})
          //this.setState({timeSubtitles_subset: TList.slice(0,10)})
          this.setState({timeSubtitles_subset: TList})
        }*/
    }

    //console.log('BUG SubtitleDisplay received blocked subtitles_times: ', nextProps.subtitles_times, this.state.timeSubtitles)
    if (
      this.state.timeSubtitles != nextProps.subtitles_times &&
      nextProps.subtitles_times.length > 0
    ) {
      //if (JSON.stringify(this.state.timeSubtitles) != JSON.stringify(nextProps.subtitles_times)) {

      //console.log('BUG: SubtitleDisplay reset timeSubtitles')
      this.setState({ timeSubtitles: nextProps.subtitles_times });
      //this.setState({timeSubtitles_subset: TList.slice(0,10)})

      if (nextProps.subtitles_times.length > 10) {
        var subtitles_slice = nextProps.subtitles_times.slice(0, 10);
        //console.log('BUG: SubtitleDisplay setting subtitles_times: ', subtitles_slice)
        subtitles_slice[0]['selected'] = true;
        this.setState({ timeSubtitles_subset: subtitles_slice });
        //console.log('SubtitleDisplay set subset ', subtitles_slice)
      } else {
        this.setState({ timeSubtitles_subset: [] });
        //console.log('BUG: SubtitleDisplay nextProps.subtitles_times.length less 10 ')
      }
    }

    this.setState({ visible: nextProps.subs });

    //if (this.state.visible) {

    if (this.props.line != nextProps.line) {
      //console.log('BUG: SubtitleDisplay props.line, nextProps.line ', this.props.line, nextProps.line)

      //this.ScrollSubtitles(nextProps.line)
      if (nextProps.line - 10 < 0) {
        if (nextProps.line - 2 >= 0) selectedE = nextProps.line - 2;
        else selectedE = 0;
        start = 0;
        end = 10;
      } else {
        start = nextProps.line - 6;
        end = nextProps.line + 4;
        selectedE = 4;
      }

      /* FIXME Case getting to end */

      var subtitles_slice = this.state.timeSubtitles.slice(start, end);
      var i, timeE;
      if (subtitles_slice.length >= selectedE) {
        timeE = subtitles_slice[selectedE]['starttime'];
      } else timeE = 0;

      //console.log('BUG: SubtitleDisplay timeE: ', timeE)
      for (i = 0; i < subtitles_slice.length; i++) {
        if (subtitles_slice[i]['starttime'] == timeE) subtitles_slice[i]['selected'] = true;
        else subtitles_slice[i]['selected'] = false;
      }

      //console.log('SubtitleDisplay subtitles_slice: ', subtitles_slice)
      this.setState({ timeSubtitles_subset: subtitles_slice });
    }
    //}
    //}
  }

  render() {
    const { timerTime, timeSubtitles, timeSubtitles_subset } = this.state;
    const { words, TBefore, TAfter, subtitles, classes } = this.props;

    return (
      <div>
        {this.state.visible ? (
          <div>
            <div className="Subtitle-buttons">
              <div className="arrow-group">
                <p> Shift by 1 second</p>
                <div className="Subtitle-buttons2">
                  <IconButton aria-label="Expand" onClick={() => this.ShiftT(1000)}>
                    <UpIcon className="Arrows" style={{ fontSize: 30 }} />
                  </IconButton>
                  <IconButton aria-label="Expand" onClick={() => this.ShiftT(-1000)}>
                    <DownIcon className="Arrows" style={{ fontSize: 30 }} />
                  </IconButton>
                </div>
              </div>

              <div className="arrow-group">
                <p> Shift by 200 ms</p>
                <div className="Subtitle-buttons2">
                  <IconButton aria-label="Expand" onClick={() => this.ShiftT(200)}>
                    <UpIcon className="Arrows" style={{ fontSize: 30 }} />
                  </IconButton>
                  <IconButton aria-label="Expand" onClick={() => this.ShiftT(-200)}>
                    <DownIcon className="Arrows" style={{ fontSize: 30 }} />
                  </IconButton>
                </div>
              </div>
            </div>

            <div className="Subtitle-buttons">
              <div className="arrow-group">
                <p> Stretch by 1 second</p>
                <div className="Subtitle-buttons2">
                  <IconButton aria-label="Expand" onClick={() => this.StretchT(-1000)}>
                    <UpIcon className="Arrows" style={{ fontSize: 30 }} />
                  </IconButton>
                  <IconButton aria-label="Expand" onClick={() => this.StretchT(1000)}>
                    <DownIcon className="Arrows" style={{ fontSize: 30 }} />
                  </IconButton>
                </div>
              </div>

              <div className="arrow-group">
                <p> Stretch by 200 ms</p>
                <div className="Subtitle-buttons2">
                  <IconButton aria-label="Expand" onClick={() => this.StretchT(-200)}>
                    <UpIcon className="Arrows" style={{ fontSize: 30 }} />
                  </IconButton>
                  <IconButton aria-label="Expand" onClick={() => this.StretchT(200)}>
                    <DownIcon className="Arrows" style={{ fontSize: 30 }} />
                  </IconButton>
                </div>
              </div>
            </div>

            <div className="scroll-container" id="scroll-container">
              {/*<List dense={true}>
            {timeSubtitles.map((Sline, i) => {
                return (
                <div>
                <ListItem button   name={i} onClick={() => this.AdjT(Sline.starttime)}>
                    <ListItemText
                        primary={Sline.line}
                    />
                    
                </ListItem>
                <Divider />
                </div>
                )
                })}
              </List>*/}
              {/*timeSubtitles.length > 0 ? ( 
            <List dense={true}>
            
              {timeSubtitles.map((Sline, i) => {
                  return (
                  <div>
                  <ListItem button   name={i} onClick={() => this.AdjT(Sline.starttime)}>
                      <ListItemText
                          primary={Sline.line}
                      />    
                  </ListItem>
                  <Divider />
                  </div>
                  )
                  })}
                </List>):null*/}
              {timeSubtitles_subset.length > 0 ? (
                <List dense={true}>
                  {timeSubtitles_subset.map((Sline, i) => {
                    return (
                      <div>
                        {Sline.selected ? (
                          <StyledListItem
                            button
                            name={i}
                            onClick={() => this.AdjT(Sline.starttime)}
                          >
                            <ListItemText primary={Sline.line} />
                          </StyledListItem>
                        ) : (
                          <ListItem button name={i} onClick={() => this.AdjT(Sline.starttime)}>
                            <ListItemText
                              classes={{ primary: classes.NormalItem }}
                              primary={Sline.line}
                            />
                          </ListItem>
                        )}

                        <Divider />
                      </div>
                    );
                  })}
                </List>
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default withStyles(styles)(SubtitleDisplay);
