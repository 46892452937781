import React from 'react';
import axios from 'axios';
import * as common from '../../common.js';

export default function watchedTitle(userID, titleID, tvID) {
  //console.log('database: watchedTitle: ', userID, titleID, tvID )

  // Write ID to database

  var datapack = {
    user: userID,
    titleID: titleID,
    tvID: tvID,
  };

  //console.log('Feedback: You did a submit')
  fetch(`${common.DJANGOURL}/api/titlehistorypost/`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(datapack),
  })
    .then(function(response) {
      return response.json();
    })
    .then(function(data) {
      //console.log("TitleHistory: Data is ok", data);
    })
    .catch(function(ex) {
      //console.log("TitleHistory: parsing failed", ex);
    });

  //console.log('TitleHistory: Completed submit')
}
