import React, { Component } from 'react';
import shortid from 'shortid';

import WrapSearch from './Search_Generic';

class ListsOfSearches extends Component {

  state = {
    search_name: '',
    startyear: '',
    endyear: ''
  }

  componentDidMount() {
    //console.log('ListOfSearches: ',this.props.match )
  }

  /*componentWillReceiveProps (nextProps, nextState) {
    //console.log('ListOfSearches componentWillReceiveProps ', nextProps, this.props, (JSON.stringify([this.state.search_name,this.state.startyear,this.state.endyear])  != JSON.stringify([nextProps.match.params.search_name,nextProps.match.params.filter_choices_startyear,nextProps.match.params.filter_choices_endyear]) ) )
    if (JSON.stringify([this.state.search_name,this.state.startyear,this.state.endyear]) 
    != JSON.stringify([nextProps.match.params.search_name,nextProps.match.params.filter_choices_startyear,nextProps.match.params.filter_choices_endyear])) {
      this.setState({search_name:nextProps.match.params.search_name,
                    startyear: nextProps.match.params.filter_choices_startyear,
                    endyear: nextProps.match.params.filter_choices_endyear })
    } 
  }*/


  shouldComponentUpdate(nextProps, nextState) {
    return true
  }

  render() {
    //console.log('ListOfSearches: ',this.props.match )
    return (
      <div>
        
    

        {/*<WrapSearch
          search_type="name"
          search_name={this.state.search_name}
          startyear={this.state.startyear}
          endyear={this.state.endyear}
          search_title="Title results"
        />
        <WrapSearch
          search_type="starring"
          search_name={this.state.search_name}
          startyear={this.state.startyear}
          endyear={this.state.endyear}
          search_title="Actor results"
        />*/}
        <WrapSearch
          search_type="name"
          search_name={this.props.match.params.search_name}
          startyear={this.props.match.params.filter_choices_startyear}
          endyear={this.props.match.params.filter_choices_endyear}
          blasphemy={this.props.match.params.filter_choices_blasphemy}
          profanity={this.props.match.params.filter_choices_profanity}
          sexual={this.props.match.params.filter_choices_sexual}
          racial={this.props.match.params.filter_choices_racial}
          rating={this.props.match.params.filter_choices_rating}
          bwords={this.props.match.params.filter_choices_bwords}
          ageratings={this.props.match.params.filter_choices_ageratings}
          netflix={this.props.match.params.filter_choices_netflix}
          region={this.props.match.params.filter_choices_region}
          search_title="Title results"
        />
        <WrapSearch
          search_type="starring"
          search_name={this.props.match.params.search_name}
          startyear={this.props.match.params.filter_choices_startyear}
          endyear={this.props.match.params.filter_choices_endyear}
          blasphemy={this.props.match.params.filter_choices_blasphemy}
          profanity={this.props.match.params.filter_choices_profanity}
          sexual={this.props.match.params.filter_choices_sexual}
          racial={this.props.match.params.filter_choices_racial}
          rating={this.props.match.params.filter_choices_rating}
          bwords={this.props.match.params.filter_choices_bwords}
          ageratings={this.props.match.params.filter_choices_ageratings}
          netflix={this.props.match.params.filter_choices_netflix}
          region={this.props.match.params.filter_choices_region}
          search_title="Actor results"
        />
        
      </div>
    );
  }
}

export default ListsOfSearches;
