import React, { Component } from 'react';
import { Router, browserHistory } from 'react-router';

import { connect } from 'react-redux';
import {
  fetchUserRequest,
  userLoginRequest,
  userLoginWithProviderRequest,
  userLogoutRequest
} from '../../../redux/actions';
import { Redirect } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import google_image from '../../../assets/Google_G_Logo.svg';
import threewise_image from '../../../assets/3wise_logo_nologo.png';
import { Link } from 'react-router-dom';
import View from 'react';
import Text from 'react';

import * as constants from '../constants.js'

// <SignUpLink />

const styles = theme => ({
  container: {
    margin: 'auto',
    marginTop: 100,
    maxWidth: 400,
    color: '#222222',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#EEEEEE',
  },

  loginError: {
    width: '340px',
    paddingLeft: '5px',
    paddingRight: '5px',
    paddingBottom: '10px',
    paddingTop: '10px',
    fontSize: '14px',
    color: '#222222',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#ffefef',
    border: '1px solid #cc0000',
  },

  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    minWidth: 300,
  },

  button: {
    margin: theme.spacing.unit,
    textTransform: 'none',
  },

  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
});

class SignInPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      pass: '',
      goHome: false,
      cancel: false,
      goSignUp: false,
      error: '',
      errorType: ''
    };
    //this.onFormSubmit = this.onFormSubmit.bind(this);
    this.loginWithProvider = this.loginWithProvider.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    console.log('Signin: componentWillReceiveProps: ', nextProps.currentUser)
   
      //console.log('Signin Page: componentWillReceiveProps ')
      //this.props.history.push('/');
      //browserHistory.push('/')
    //console.log('Signin: componentWillReceiveProps error: ', nextProps.currentUser.error)
    if (nextProps.currentUser.error) {
      if (nextProps.currentUser.error.errorCode == constants.ERROR_CODE_NOT_AUTHORIZED) {
        //console.log(constants.ERROR_MSG_USER_NOT_FOUND);
        this.props.logoutUser()
        this.setState({ error: constants.ERROR_MSG_NOT_AUTHORIZED, errorType: constants.ERROR_CODE_NOT_AUTHORIZED });
      }
      //console.log('Signin: componentWillReceiveProps errorCode: ', nextProps.currentUser.error.errorCode)
      if (nextProps.currentUser.error.errorCode == constants.ERROR_CODE_USER_NOT_FOUND) {
        //console.log(constants.ERROR_MSG_USER_NOT_FOUND);
        this.setState({ error: constants.ERROR_MSG_USER_NOT_FOUND, errorType: constants.ERROR_CODE_USER_NOT_FOUND });
      }
      if (nextProps.currentUser.error.errorCode == constants.ERROR_CODE_WRONG_PASSWORD) {
        //console.log(constants.ERROR_MSG_WRONG_PASSWORD);
        this.setState({ error: constants.ERROR_MSG_WRONG_PASSWORD, errorType: constants.ERROR_CODE_WRONG_PASSWORD});
      }
    }
    else {
      if (nextProps.currentUser.isLoggedIn)
        this.setState(({goHome: true, error: '', errorType: ''}))
    }

  }

  handleChange = event => {
   
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  createAccount = () => {
    //console.log('SignIn: Create Account');
    this.setState({ goSignUp: true });
  };

  signinUserPass = () => {
    //event.preventDefault();
    // TODO remove depricated "this."
    const email = this.state.email;
    const pass = this.state.pass;
    this.props.loginUser({ email, pass });
    //this.props.history.push('/');
  }

  loginWithProvider(provider) {
    this.props.loginWithProvider(provider);

    //this.props.history.push('/');
    //browserHistory.push('/profile')
    // this.props.loginWithProvider(provider).then((data) => {
    //     if (data.payload.errorCode) {
    //         this.setState({ message: data.payload.errorMessage })
    //     } else {
    //         browserHistory.push('/profile')
    //     }
    // })
    //this.props.OnDone()
   
  }

  shouldComponentUpdate(nextProps, nextState) {
  
    //console.log('Signin: ShouldUpdate')
    return true;
  }

  handleClose = () => {
    this.setState({ goHome: true, cancel: true });
    this.props.OnDone();
  };

  render() {
    const { classes, ...other } = this.props;
    const { error,errorType } = this.state;
    //console.log ('SignIn: ', this.state.goSignUp, this.props.currentUser)

    if (this.state.goHome && (this.props.currentUser.isLoggedIn || this.state.cancel)) {
      return <Redirect to="/" />;
    }

    return (
   
        <div className={classes.container}>
        
          <h4>Login into 3Wise Admin</h4>

          {errorType==constants.ERROR_MSG_WRONG_PASSWORD? (
            <div className={classes.loginError}>
              <div>
                {error}
                {' '}
                <Link to={'/passwordforget'} style={{ textDecoration: 'none', color: 'blue' }}>
                  Have you forgotten your password?
                </Link>
              </div>
            </div>
          ) : null}
          {errorType==constants.ERROR_CODE_USER_NOT_FOUND? (
            <div className={classes.loginError}>
              <div>
                {error}
                {' '}
              </div>
            </div>
          ) : null}
          {errorType==constants.ERROR_CODE_NOT_AUTHORIZED? (
            <div className={classes.loginError}>
              <div>
                {error}
                {' '}
              </div>
            </div>
          ) : null}



          <TextField
            name="email"
            label="Email"
            id="txtEmail"
            color="default"
            value={this.state.email}
            className={classes.textField}
            onChange={e => this.handleChange(e)}
            margin="normal"
          />
          <TextField
            name="pass"
            label="Password"
            id="txtPass"
            color="default"
            value={this.state.pass}
            className={classes.textField}
            onChange={e => this.handleChange(e)}
            type="password"
            margin="normal"
          />

          <div
            style={{
              minWidth: '300px',
              marginmarginTop: '0px',
              fontSize: 15,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            <p>
              <Link to={'/passwordforget'} style={{ textDecoration: 'none', color: 'blue' }}>
                Forgot your password?
              </Link>
            </p>
          </div>

          <div>
            <Button
              variant="contained"
              size="medium"
              color="default"
              fullWidth={true}
              onClick = {this.signinUserPass} 
              className={classes.button}
              style={{ width: '250px', marginTop: '20px' }}
            >
              Sign In
            </Button>
          </div>

          <div>
            <Button
              variant="contained"
              size="medium"
              color="default"
              fullWidth={true}
             
              onClick={() => {
                this.loginWithProvider('google');
              }}
              className={classes.button}
              style={{ width: '250px', marginBottom: '20px' }}
            >
              <div style={{ display: 'flex' }}>
                <img src={google_image} height={20} width={20} />{' '}
                <div>&nbsp; Sign In with Google</div>
              </div>
            </Button>
          </div>
          <br />

         
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser,
  };
}

const mapDispatchToProps = dispatch => ({
  fetchUser: () => dispatch(fetchUserRequest()),
  loginUser: user => dispatch(userLoginRequest(user)),
  loginWithProvider: provider => dispatch(userLoginWithProviderRequest(provider)),
  logoutUser: () => dispatch(userLogoutRequest()),
});

const WrapSignInPage = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SignInPage));

export default WrapSignInPage;
