import React, { Component } from 'react';

import ScrollMenu from 'react-horizontal-scrolling-menu';
//import ScrollMenu from '../../../components/Widgets/ScrollMenu/scrollMenu.tsx'
import WrapSingleMovie from './SingleMovie.js';
import axios from 'axios';
import * as common from '../../../common.js';
import { isMobile } from 'react-device-detect';
import LoadingOverlay from 'react-loading-overlay';

import IconButton from '@material-ui/core/IconButton';
import { Swipeable } from 'react-swipeable';
import {
  addBookmarkTitle,
  delBookmarkTitle,
  initBookmarkTitle
} from '../../../redux/actions/index';
import { connect } from 'react-redux';

const RIGHT = '-1';
const LEFT = '+1';
const MAXTITLES = 50;

const Arrow = ({ text, className }) => {
  return <div className={className}>{text}</div>;
};

const ArrowRightSVG = ({ size, className }) => {
  return (
    <div className={className}>
      <svg height="16" width="16">
        <polygon points="0,0 16,8 0,16" fill="darkgrey" />
      </svg>
    </div>
  );
};

const ArrowLeftSVG = ({ size, className }) => {
  return (
    <div className={className}>
      <svg height="16" width="16">
        <polygon points="16,0 0,8 16,16" fill="darkgrey" />
      </svg>
    </div>
  );
};

function ArrowLeftSVGF() {
  return (
    <div className={'arrow-prev'}>
      <svg height="16" width="16">
        <polygon points="16,0 0,8 16,16" fill="darkgrey" />
      </svg>
    </div>
  );
}

function ArrowRightSVGF() {
  return (
    <div className={'arrow-prev'}>
      <svg height="16" width="16">
        <polygon points="0,0 16,8 0,16" fill="darkgrey" />
      </svg>
    </div>
  );
}

const styles = theme => ({
  horizontalScroll: {
    overflowY: 'scroll',
    overflowX: 'scroll',
    overflow: 'scroll',
    zIndex: 0,
    scrollBehavior: 'smooth',
  },
});

const persistSyntheticEvent = (func, persist) => {
  return (e, ...rest) => {
    if (persist && e.persist) e.persist();
    return func(e, ...rest);
  };
};

//const ArrowLeft = Arrow({ text: "<", className: "arrow-prev" });
//const ArrowRight = Arrow({ text: ">", className: "arrow-next" });
const ArrowLeft = ArrowLeftSVG({ size: 100, className: 'arrow-prev' });
const ArrowRight = ArrowRightSVG({ size: 100, className: 'arrow-next' });



class ListHistory extends Component {
  constructor(props) {
    super(props);
    this.menu1 = null;
    this.mounted = false;
    this.showMovieInfoPrevious = false;
  }

  state = {
    themovies: [],
    movie: {},
    showInProcess: false,
    loading: false,
    lastItemVisible: false,
    firstItemVisible: false,
    inertiascrolling: true,
    slowdownFactor: 0.75,
    titleCount: 10,

    pendingRightShift: false,
    reachedLastTitle: false,
    totalTitlesFetched: 0,
    newSearch: false,
    swipeDirection: 'none',
    persistEvent: true,
  };

  //this.SearchTitle(this.state.searchYear,this.state.searchRating,20,title,false,true)

  /*
  SearchTitle = (userID, titleCount, lazyLoad, newSearch) => {
    //console.log('Browse, List.js: newSearch: ', newSearch);
    //console.log('Browse, List.js: lazyload: ', lazyLoad);

    var newTotalTitlesFetched;
    var movieData = [];
    this.setState({ loading: true });

    var apistr = '';
    var movielistRedux = []

    apistr = `${common.DJANGOURL}/api/titlehistory/?id=${userID}`;

    axios
      .get(apistr)
      .then(res => {
        if (this.mounted) {
          movieData = res.data.slice(0, titleCount)
          newTotalTitlesFetched = movieData.length;

          //console.log('Lists.js  newTotalTitlesFetched', newTotalTitlesFetched);
          //console.log('Lists.js  state.TitlesFetched', this.state.totalTitlesFetched);
          //console.log('Lists.js , SearchTitle: newSearch: ', newSearch);
          //console.log('ListHistory.js: ', movieData)

          if (newTotalTitlesFetched != this.state.totalTitlesFetched || newSearch) {
            if (newSearch) {
              this.setState({
                reachedLastTitle: false,
                themovies: movieData,
                loading: false,
                pendingRightShift: false,
                totalTitlesFetched: newTotalTitlesFetched,
                titleCount: 10,
                newSearch: true,
              });
              // Add to redux state
              movielistRedux = res.data.map(a => a.titleID.imdbID);
              this.props.initBookmarkTitle(movielistRedux)
              //console.log('movieRedux: ', movielistRedux)
            } else {
              this.setState({
                reachedLastTitle: false,
                themovies: movieData,
                loading: false,
                pendingRightShift: false,
                totalTitlesFetched: newTotalTitlesFetched,
              });
            }
            //this.renderRedirect();
          } else {
            this.setState({ loading: false, pendingRightShift: false, reachedLastTitle: true });
          }
        }
      })
      .catch(err => {
        this.setState({ loading: false });
        //console.log('Browse: Get DB Error');
      });


  }*/

  SearchTitle = (userID, titleCount, lazyLoad, newSearch) => {
    this.setState({
      themovies: this.props.bookmarks,
      loading: false,
      pendingRightShift: false,
      totalTitlesFetched: this.props.bookmarks.length,
      titleCount: 10,
      newSearch: true,
    })
  }





  componentDidMount() {
    //console.log('List.js component mount')
    this.mounted = true;
    this.showMovieInfoPrevious = this.props.showMovieInfo;
    this.setState({
      themovies: this.props.bookmarks,
      loading: false,
      pendingRightShift: false,
      totalTitlesFetched: this.props.bookmarks.length,
      titleCount: 50,
      newSearch: true,
    })

    //this.SearchTitle(this.props.userID, 10, false, true);
  }

  componentWillUnmount() {
    //console.log('List.js component unmount')
    this.mounted = false;
  }

  componentWillReceiveProps(nextProps) {
    //console.log('Received props: ', nextProps)
    this.setState({
      themovies: nextProps.bookmarks,
      loading: false,
      pendingRightShift: false,
      totalTitlesFetched: nextProps.bookmarks.length,
      titleCount: 50,
      newSearch: true,
    })
  }



  LeftArrowProcess = () => {
    setTimeout(() => {
      var newLastVisible = this.menu1.checkFirstLastItemVisibility({})['lastItemVisible'];
      this.setState({ lastItemVisible: newLastVisible });
    }, 500);
  };


  RightArrowProcess = () => {
    var lazyload, newTitleCount, newSearch;

    setTimeout(() => {
      var newLastVisible = this.menu1.checkFirstLastItemVisibility({})['lastItemVisible'];
      lazyload = this.state.lastItemVisible && newLastVisible;
      //console.log('Browser List onUpdate: ', this.state.lastItemVisible, newLastVisible, lazyload )
      //console.log('Browser List: Lazyload', lazyload)
      if (lazyload) {
        //console.log('Browser List: titleCount: ', this.state.titleCount)
        if (this.state.titleCount < MAXTITLES) {
          newTitleCount = this.state.titleCount + 10;
          this.setState({
            loading: true,
            titleCount: newTitleCount,
            lastItemVisible: newLastVisible,
            pendingRightShift: true,
          });
          newSearch = false;
          this.SearchTitle(this.props.userID, newTitleCount, true, newSearch);
        } else this.setState({ reachedLastTitle: true });
      }
      this.setState({ lastItemVisible: newLastVisible });
    }, 500);
  };


  TitlesClickLeftArrow = () => {
    //console.log('Search: Titlesclickleftarrow')
    //console.log('Search: Met condition Titlesclickleftarrow')
    this.menu1.handleArrowClick();
    //this.LeftArrowProcess();
  };


  TitlesClickRightArrow = () => {
    //console.log('Search: Titlesclickleftarrow')
    //console.log('Search: Met condition Titlesclickleftarrow')
    this.menu1.handleArrowClickRight();
    //this.RightArrowProcess();
  };

  componentDidUpdate() {
    if (this.state.pendingRightShift) {
      this.TitlesClickRightArrow();
      this.setState({ pendingRightShift: false });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    //console.log('List.js shouldComponentUpdate', nextState.themovies,this.state.themovies );
    if (this.mounted) return true;
    /*if (this.state.themovies === nextState.themovies) {
      return false;
    } else {
      return true;
    }*/
  }

  buildBlankMovie = index => {
    var rtime1 = Math.floor(Math.random() * 10) + 1;
    var strrtime1 = rtime1.toString() + 's';
    return (
      <div className="menu-item" key={index}>
        <svg width="150" height="225" viewBox="0 0 150 225">
          <rect width="150" height="225" rx="10" ry="10" fill="url(#skyGradient)" />
          <linearGradient id="skyGradient" x1="100%" y1="100%">
            <stop offset="100%" stopColor="lightblue" stopOpacity="1">
              <animate
                attributeName="stop-color"
                values="#111111;#222222;#111111"
                dur="2s"
                repeatCount="indefinite"
              />
            </stop>
          </linearGradient>
        </svg>
      </div>
    );
  };

  onSwiped(direction) {
    if (direction === LEFT) {
      this.setState({ swipeDirection: 'Right' });
      this.RightArrowProcess();
    } else {
      this.setState({ swipeDirection: 'Left' });
      this.LeftArrowProcess();
    }
  }

  onSwipedDirection(direction) {
    this.setState({
      swipeDirection: direction,
    });
  }

  onSwiping(args) {
    if (args.dir == 'Left') {
      this.RightArrowProcess();
      this.setState({ swipeDirection: 'Left' });
    } else {
      this.LeftArrowProcess();
      this.setState({ swipeDirection: 'Right' });
    }
  }

  render() {
    const { persistEvent, loading } = this.state;

    //console.log('List.js movies: ', this.state.themovies)
    //console.log ('ListHistory: ', this.props)

    const mlen = this.state.themovies.length;
    const movieEmpty = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    var movieData = [];
    const movieFull = this.state.themovies.map((movie, index) => {
      //console.log('ListHistory.js: movie ', movie);
      if (movie)
        if (index == mlen - 1) {
          //console.log('List.js reached last movie: ', movie,index)
          return (
            <div className="menu-item-last" key={movie.imdbID}>
              <WrapSingleMovie movie={movie} netflix_region={this.props.region} />
            </div>
          );
        } else {
          return (
            <div className="menu-item" key={movie.imdbID}>
              <WrapSingleMovie movie={movie} netflix_region={this.props.region} />
            </div>
          );
        }
      else {
        return (
          <div className="menu-item"></div>
        )
      }
    });

    const movieLoading = movieEmpty.map(index => {
      return this.buildBlankMovie(index);
    });

    //if (this.state.themovies.length > 0)
    //console.log('List.js: movieFull: ', movieFull)
    if (!loading) {
      movieData = movieFull;
      //console.log('List.js: movieData not loading ', movieData)
    } else {
      //movieData = movieLoading;

      if (movieFull.length > 0) movieData = movieFull.concat(movieLoading);
      else movieData = movieLoading;
      //console.log('List.js: movieData loading ', movieData)
    }

    let swipeableDirProps = {};
    swipeableDirProps.onSwiping = persistSyntheticEvent(
      (...args) => this.onSwiping(...args),
      persistEvent,
    );

    /*
          onSwipedLeft={() => this.onSwiped(LEFT)}
          onSwipedRight={() => this.onSwiped(RIGHT)}
         preventDefaultTouchmoveEvent 
         {...swipeableDirProps}
         <h4 style={{marginLeft:'10px', color: 'black' }} >{this.state.swipeDirection}</h4>*/

    return (
      <div>

        {(movieData.length > 0) ? <div>
          {isMobile ? (
            <div style={{ marginTop: '40px' }}>
              <h4 style={{ marginLeft: '10px', color: 'black' }}>{this.props.heading}</h4>
              <Swipeable trackMouse {...swipeableDirProps}>
                <ScrollMenu
                  ref={el => {
                    this.menu1 = el;
                  }}
                  data={movieData}
                  wheel={false}
                  dragging={true}
                  alignCenter={false}
                  clickWhenDrag={false}
                  innerWrapperStyle={{ display: 'flex', alignItems: 'center' }}
                  inertiaScrolling={true}
                  inertiaScrollingSlowdown={0.75}
                />
              </Swipeable>
            </div>
          ) : (
              <div style={{ marginTop: '40px' }}>
                <h4 style={{ marginLeft: '50px', color: 'black' }}>{this.props.heading}</h4>

                <div className="lists-layout">
                  <div className="lists-button">
                    <IconButton
                      style={{ backgroundColor: '#dddddd' }}
                      aria-label="Expand"
                      onClick={this.TitlesClickLeftArrow}
                    >
                      <ArrowLeftSVGF />
                    </IconButton>
                  </div>

                  <div className="lists-scrollmenu">
                    <ScrollMenu
                      ref={el => {
                        this.menu1 = el;
                      }}
                      data={movieData}
                      wheel={false}
                      dragging={true}
                      alignCenter={false}
                      innerWrapperStyle={{ display: 'flex', alignItems: 'center' }}
                      clickWhenDrag={false}
                    />
                  </div>

                  <div className="button">
                    {!(this.state.reachedLastTitle && this.state.lastItemVisible) && (
                      <IconButton
                        style={{ backgroundColor: '#dddddd' }}
                        aria-label="Expand"
                        onClick={this.TitlesClickRightArrow}
                      >
                        <ArrowRightSVGF />
                      </IconButton>
                    )}
                  </div>
                </div>
              </div>
            )}

        </div> : null}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    bookmarks: state.movies_redux.bookmarks,
  };
};



const mapDispatchToProps = dispatch => {
  return {
    addBookmarkTitle: content => dispatch(addBookmarkTitle(content)),
    delBookmarkTitle: content => dispatch(delBookmarkTitle(content)),
    initBookmarkTitle: content => dispatch(initBookmarkTitle(content)),
  };
};

const WrapListHistory = connect(mapStateToProps, mapDispatchToProps)(ListHistory);
export default WrapListHistory
