/***********************************************************************
 *
 * DESCRIPTION :
 *   Main React App
 *
 * PUBLIC FUNCTIONS :
 *
 * NOTES :
 *   Calls RootNavigation to display toolbar and browser / search
 *
 * AUTHOR(S) :
 *   D.L. Johnson    START DATE : 1 Jan 2019
 *
 * Copyright 3Wise 2019. All rights reserved.
 ***********************************************************************/

import React, { Component } from 'react';
import RootNavigation from './containers/Navigation/RootNavigation';
import './App.css';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import { Provider } from 'react-redux';

import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import logger from 'redux-logger';
// import sagaMonitor from 'redux-saga/sagaMonitor';

import { Router, browserHistory } from 'react-router';
// import ReduxPromise from 'redux-promise'

import reducers from './redux/reducers';
import rootSaga from './redux/sagas';

// TODO logger only fro DEV environment
// if (process.env.NODE_ENV === 'development') {
//     createStoreWithMiddleware = applyMiddleware(thunk, logger, ReduxPromise)(createStore);
// }else {
//     createStoreWithMiddleware = applyMiddleware(thunk, ReduxPromise)(createStore);
// }

// TODO logger only fro DEV environment
const sagaMiddleware = createSagaMiddleware();
const store = createStore(reducers, applyMiddleware(sagaMiddleware, logger));

sagaMiddleware.run(rootSaga);

/*const theme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#ff4400',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: '#0066ff',
      main: '#0044ff',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffcc00',
    },
  },
  typography: { useNextVariants: true },
}); */

const theme = createMuiTheme({
  palette: {
    type: 'light', // Switching the dark mode on is a single property value change.
  }
});

/*class App extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <RootNavigation/>
      </MuiThemeProvider>
    );
  }
}*/

const App = () => (
  <Provider store={store}>
    <MuiThemeProvider theme={theme}>
      <RootNavigation />
    </MuiThemeProvider>
  </Provider>
);

export default App;
