import React, { Component } from 'react';
import ScrollMenu from 'react-horizontal-scrolling-menu';
//import ScrollMenu from '../../../components/Widgets/ScrollMenu/scrollMenu.tsx'
import WrapSingleMovie from './SingleMovie.js';
import axios from 'axios';
import * as common from '../../../common.js';
import { isMobile } from 'react-device-detect';
import LoadingOverlay from 'react-loading-overlay';

import IconButton from '@material-ui/core/IconButton';
import { Swipeable } from 'react-swipeable';

const RIGHT = '-1';
const LEFT = '+1';
const SEARCHYEAR = 1970;
const SEARCHRATING = 3;
const MAXTITLES = 50;

Math.seededRandom = function(max, min, seed) {
  max = max || 1;
  min = min || 0;

  seed = (seed * 9301 + 49297) % 233280;
  var rnd = seed / 233280.0;

  return min + rnd * (max - min);
}

function shuffleArray(array,seed) {
  for (let i = array.length - 1; i > 0; i--) {
      //const j = Math.floor(Math.random() * (i + 1));
      const j = Math.floor(Math.seededRandom(1,0,seed) * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
  }
  return array
}
  

const Arrow = ({ text, className }) => {
  return <div className={className}>{text}</div>;
};

const ArrowRightSVG = ({ size, className }) => {
  return (
    <div className={className}>
      <svg height="16" width="16">
        <polygon points="0,0 16,8 0,16" fill="darkgrey" />
      </svg>
    </div>
  );
};

const ArrowLeftSVG = ({ size, className }) => {
  return (
    <div className={className}>
      <svg height="16" width="16">
        <polygon points="16,0 0,8 16,16" fill="darkgrey" />
      </svg>
    </div>
  );
};

function ArrowLeftSVGF() {
  return (
    <div className={'arrow-prev'}>
      <svg height="16" width="16">
        <polygon points="16,0 0,8 16,16" fill="darkgrey" />
      </svg>
    </div>
  );
}

function ArrowRightSVGF() {
  return (
    <div className={'arrow-prev'}>
      <svg height="16" width="16">
        <polygon points="0,0 16,8 0,16" fill="darkgrey" />
      </svg>
    </div>
  );
}

const styles = theme => ({
  horizontalScroll: {
    overflowY: 'scroll',
    overflowX: 'scroll',
    overflow: 'scroll',
    zIndex: 0,
    scrollBehavior: 'smooth',
  },
});

const persistSyntheticEvent = (func, persist) => {
  return (e, ...rest) => {
    if (persist && e.persist) e.persist();
    return func(e, ...rest);
  };
};

const RandomizeResult = (res, seed) => {

  //randomArray is the seed
  var finalres;

  // Shuffle first 20, then in groups of 10

  if (res.length <= 20)
    finalres = shuffleArray(res,seed)
  else {
    var first_twenty = res.slice(0,20);
    //console.log('randomize: first_twenty', res)
    finalres = shuffleArray(first_twenty,seed)
    //console.log('randomize: first_twenty_rand', finalres)
    var blocks_ten = Math.floor((res.length - 20)/10)
    //console.log('randomize: blocks_ten', blocks_ten)
   
    for (var i=0; i<blocks_ten; i++) {
      var idx = 20+i*10
      var next_ten = res.slice(idx,idx+10)
      //console.log('randomize: next_ten', idx, next_ten)
      var next_ten_rand = shuffleArray(next_ten,seed)
      //console.log('randomize: next_ten_rand', next_ten_rand)
      finalres.push(...next_ten_rand)
      //console.log('randomize: finalres', finalres)
    }
    
    var blocks_left = res.length % 10;
    if (blocks_left>0) {
      idx = 20+i*10;
      var remaining = res.slice(idx,idx+blocks_left);
      var remaining_rand = shuffleArray(remaining);
      finalres.push(...remaining_rand)
    }

    //finalres = res
  }

  return finalres 

}

//const ArrowLeft = Arrow({ text: "<", className: "arrow-prev" });
//const ArrowRight = Arrow({ text: ">", className: "arrow-next" });
const ArrowLeft = ArrowLeftSVG({ size: 100, className: 'arrow-prev' });
const ArrowRight = ArrowRightSVG({ size: 100, className: 'arrow-next' });

class WrapNetflixNew extends Component {
  constructor(props) {
    super(props);
    this.menu1 = null;
    this.mounted = false;
    this.showMovieInfoPrevious = false;
  }

  state = {
    themovies: [],
    movie: {},
    showInProcess: false,
    loading: false,
    lastItemVisible: false,
    firstItemVisible: false,
    inertiascrolling: true,
    slowdownFactor: 0.75,
    titleCount: 20,
    searchYear: SEARCHYEAR,
    searchRating: SEARCHRATING,
    pendingRightShift: false,
    reachedLastTitle: false,
    totalTitlesFetched: 0,
    newSearch: false,
    swipeDirection: 'none',
    persistEvent: true,
  };

  //this.SearchTitle(this.state.searchYear,this.state.searchRating,20,title,false,true)
  SearchTitle = (searchYear, searchRating, title, filterChoices, titleCount, lazyLoad, newSearch, seed, region) => {
    //console.log('Browse, List.js: newSearch: ', newSearch);
    //console.log('Browse, List.js: lazyload: ', lazyLoad);
    //console.log('List.js: SearchTitle: ', filterChoices, filterChoices['locklanguage']);

    var newTotalTitlesFetched;
    var movieData = [];
    this.setState({ loading: true });



    var apistr = '';
    //&rating=${searchRating}
    if (this.props.type == 'movie') {
      /*apistr = `${common.DJANGOURL}/api/moviegenres/?startyear=${filterChoices['startyear']}&endyear=${filterChoices['endyear']}
      &count=${titleCount}&country=${region}&ageratings=${filterChoices['ageratings']}&netflixstart=2020-03-27`;*/
      
      apistr = `${common.DJANGOURL}/api/moviegenres/?count=${titleCount}&country=${region}
      &ageratings=${filterChoices['ageratings']}&netflixstart=2020-03-27&sort=date`;

      if (filterChoices['locklanguage'])
        apistr = apistr.concat(`&olanguage=${filterChoices['original_language']}`)
      
      if (!filterChoices['excludeBlasphemy'])
        apistr = apistr.concat(`&blasphemy=${filterChoices['blasphemy']}`)
      if (!filterChoices['excludeProfanity'])
        apistr = apistr.concat(`&profanity=${filterChoices['profanity']}`)
      if (!filterChoices['excludeSexual'])
        apistr = apistr.concat(`&sexual=${filterChoices['sexual']}`)
      if (!filterChoices['excludeRacial'])
        apistr = apistr.concat(`&racial=${filterChoices['racial']}`)
      
      apistr = apistr.concat(`&netflix=1`)

      //console.log('List.js: apistr = ', apistr, filterChoices)
      //apistr = common.DJANGOURL+ '/api/moviegenres/?year=1960&rating=4&genre=' + this.props.title;
    } else {
      /*apistr = `${common.DJANGOURL}/api/tvgenres/?startyear=${filterChoices['startyear']}&endyear=${filterChoices['endyear']}
      &count=${titleCount}&country=${region}&ageratings=${filterChoices['ageratings']}&netflixstart=2020-03-27`;*/
      apistr = `${common.DJANGOURL}/api/tvgenres/?count=${titleCount}&country=${region}
      &ageratings=${filterChoices['ageratings']}&netflixstart=2020-03-27&sort=date`;

      
      /*if (filterChoices['locklanguage'])
        apistr = apistr.concat(`&olanguage=${filterChoices['original_language']}`)
      if (!filterChoices['excludeBlasphemy'])
        apistr = apistr.concat(`&blasphemy=${filterChoices['blasphemy']}`)
      if (!filterChoices['excludeProfanity'])
        apistr = apistr.concat(`&profanity=${filterChoices['profanity']}`)
      if (!filterChoices['excludeSexual'])
        apistr = apistr.concat(`&sexual=${filterChoices['sexual']}`)
      if (!filterChoices['excludeRacial'])
        apistr = apistr.concat(`&racial=${filterChoices['racial']}`)*/
      
      apistr = apistr.concat(`&netflix=1`)


      //apistr = `${common.DJANGOURL}/api/tvgenres/?year=${searchYear}&count=${titleCount}&genre=${title}`;
      //apistr = common.DJANGOURL+ '/api/tvgenres/?year=1960&rating=4&genre=' + this.props.title;
    }

    axios
      .get(apistr)
      .then(res => {
        if (this.mounted) {
          // Dont' randomize Netflix New
          //movieData = RandomizeResult(res.data, seed);
          movieData = res.data;

          newTotalTitlesFetched = movieData.length;

          //console.log('Lists.js  newTotalTitlesFetched', newTotalTitlesFetched);
          //console.log('Lists.js  state.TitlesFetched', this.state.totalTitlesFetched);
          //console.log('Lists.js , SearchTitle: newSearch: ', newSearch);
          if (newTotalTitlesFetched != this.state.totalTitlesFetched || newSearch) {
            if (newSearch) {
              this.setState({
                reachedLastTitle: false,
                themovies: movieData,
                loading: false,
                pendingRightShift: false,
                totalTitlesFetched: newTotalTitlesFetched,
                titleCount: 20,
                newSearch: true,
              });
            } else {
              this.setState({
                reachedLastTitle: false,
                themovies: movieData,
                loading: false,
                pendingRightShift: false,
                totalTitlesFetched: newTotalTitlesFetched,
              });
            }
            //this.renderRedirect();
          } else {
            this.setState({ loading: false, pendingRightShift: false, reachedLastTitle: true });
          }
        }
      })
      .catch(err => {
        this.setState({ loading: false });
        //console.log('Browse: Get DB Error');
      });

    /*
    const url =
      typeof this.props.apiCall === "number"
        ? `https://api.themoviedb.org/3/discover/movie?api_key=17117ab9c18276d48d8634390c025df4&language=en-US&sort_by=popularity.desc&include_adult=false&include_video=false&page=1&with_genres=${
            this.props.apiCall
          }`
        : `https://api.themoviedb.org/3/movie/${this.props.apiCall}?api_key=17117ab9c18276d48d8634390c025df4&language=en-US&page=1`;

    fetch(url)
      .then(r => r.json())
      .then(data => {
        if (this.mounted) this.setState({ movies: data.results });
      })
      .catch(err => //console.log(err)); */
  };

  componentDidMount() {
    //console.log('List.js component mount')
    this.mounted = true;
    this.showMovieInfoPrevious = this.props.showMovieInfo;
    this.SearchTitle(
      this.state.searchYear,
      this.state.searchRating,
      this.props.title,
      this.props.filterChoices,
      20,
      false,
      true,
      this.props.movieShuffleSeed,
      this.props.region
    );
  }

  componentWillUnmount() {
    //console.log('List.js component unmount')
    this.mounted = false;
  }

  LeftArrowProcess = () => {
    setTimeout(() => {
      var newLastVisible = this.menu1.checkFirstLastItemVisibility({})['lastItemVisible'];
      this.setState({ lastItemVisible: newLastVisible });
    }, 500);
  };

  TitlesClickLeftArrow = () => {
    //console.log('Search: Titlesclickleftarrow')
    //console.log('Search: Met condition Titlesclickleftarrow')
    this.menu1.handleArrowClick();
    this.LeftArrowProcess();
  };

  RightArrowProcess = () => {
    var lazyload, newTitleCount, newSearch;
    //console.log'List.js: RightArrowProcess: ',this.menu1.checkFirstLastItemVisibility({})['lastItemVisible'])

    setTimeout(() => {
      var newLastVisible = this.menu1.checkFirstLastItemVisibility({})['lastItemVisible'];
      lazyload = this.state.lastItemVisible && newLastVisible;
      //console.log'Browser List onUpdate: ', this.state.lastItemVisible, newLastVisible, lazyload )
      //console.log('Browser List: Lazyload', lazyload)
      if (lazyload) {
        //console.log('Browser List: titleCount: ', this.state.titleCount)
        if (this.state.titleCount < MAXTITLES) {
          newTitleCount = this.state.titleCount + 10;
          this.setState({
            loading: true,
            titleCount: newTitleCount,
            lastItemVisible: newLastVisible,
            pendingRightShift: true,
          });
          newSearch = false;
          this.SearchTitle(
            this.state.searchYear,
            this.state.searchRating,
            this.props.title,
            this.props.filterChoices,
            newTitleCount,
            true,
            newSearch,
            this.props.movieShuffleSeed,
            this.props.region
          );
        } else this.setState({ reachedLastTitle: true });
      }
      this.setState({ lastItemVisible: newLastVisible });
    }, 500);
  };

  TitlesClickRightArrow = () => {
    //console.log'List.js: Titlesclickrightarrow: ', this.menu1)
    //console.log('Search: Met condition Titlesclickleftarrow')
    this.menu1.handleArrowClickRight();
    this.RightArrowProcess();
  };

  componentDidUpdate() {
    if (this.state.pendingRightShift) {
      this.TitlesClickRightArrow();
      this.setState({ pendingRightShift: false });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    //console.log'List.js shouldComponentUpdate', this.mounted, nextState.themovies,this.state.themovies );
    if (this.mounted) return true;
    /*if (this.state.themovies === nextState.themovies) {
      return false;
    } else {
      return true;
    }*/
  }

  buildBlankMovie = index => {
    var rtime1 = Math.floor(Math.random() * 10) + 1;
    var strrtime1 = rtime1.toString() + 's';
    return (
      <div className="menu-item" key={index}>
        <svg width="150" height="225" viewBox="0 0 150 225">
          <rect width="150" height="225" rx="10" ry="10" fill="url(#skyGradient)" />
          <linearGradient id="skyGradient" x1="100%" y1="100%">
            <stop offset="100%" stopColor="lightblue" stopOpacity="1">
              <animate
                attributeName="stop-color"
                values="#111111;#222222;#111111"
                dur="2s"
                repeatCount="indefinite"
              />
            </stop>
          </linearGradient>
        </svg>
      </div>
    );
  };

  onSwiped(direction) {
    if (direction === LEFT) {
      this.setState({ swipeDirection: 'Right' });
      this.RightArrowProcess();
    } else {
      this.setState({ swipeDirection: 'Left' });
      this.LeftArrowProcess();
    }
  }

  onSwipedDirection(direction) {
    this.setState({
      swipeDirection: direction,
    });
  }

  onSwiping(args) {
    if (args.dir == 'Left') {
      this.RightArrowProcess();
      this.setState({ swipeDirection: 'Left' });
    } else {
      this.LeftArrowProcess();
      this.setState({ swipeDirection: 'Right' });
    }
  }

  render() {
    const { persistEvent, loading } = this.state;

    //console.log('List.js shuffle: ', this.props.movieShuffleSeed)
    //console.log('List.js movies: ', this.props.filterChoices)
    //console.log('List: netflix region: ', this.props.region)
    const mlen = this.state.themovies.length;
    const movieEmpty = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    var movieData = [];

    const movieFull = this.state.themovies.map((movie, index) => {
      if (movie) {
        if (index == mlen - 1) {
          //console.log('List.js reached last movie: ', movie,index)
          return (
            <div className="menu-item-last" key={movie.imdbID}>
              <WrapSingleMovie movie={movie} netflix_region={this.props.region} />
            </div>
          );
        } else {
          return (
            <div className="menu-item" key={movie.imdbID}>
              <WrapSingleMovie movie={movie} netflix_region={this.props.region} />
            </div>
          );
        }
      }
      else
        return (
          <div className="menu-item"></div>
        )
    });

    const movieLoading = movieEmpty.map(index => {
      return this.buildBlankMovie(index);
    });

    //if (this.state.themovies.length > 0)
    //console.log('List.js: movieFull: ', movieFull)
    if (!loading) {
      movieData = movieFull;
      //console.log('List.js: movieData not loading ', movieData)
    } else {
      //movieData = movieLoading;

      if (movieFull.length > 0) movieData = movieFull.concat(movieLoading);
      else movieData = movieLoading;
      //console.log('List.js: movieData loading ', movieData)
    }

    let swipeableDirProps = {};
    swipeableDirProps.onSwiping = persistSyntheticEvent(
      (...args) => this.onSwiping(...args),
      persistEvent,
    );

    /*
          onSwipedLeft={() => this.onSwiped(LEFT)}
          onSwipedRight={() => this.onSwiped(RIGHT)}
         preventDefaultTouchmoveEvent 
         {...swipeableDirProps}
         <h4 style={{marginLeft:'10px', color: 'black' }} >{this.state.swipeDirection}</h4>*/

    return (
      <div>
        {isMobile ? (
          <div style={{ marginTop: '40px' }}>
            <h4 style={{ marginLeft: '10px', color: 'black' }}>{this.props.heading}</h4>

            <Swipeable trackMouse {...swipeableDirProps}>
              <ScrollMenu
                ref={el => {
                  this.menu1 = el;
                }}
                data={movieData}
                wheel={false}
                dragging={true}
                alignCenter={false}
                clickWhenDrag={false}
                innerWrapperStyle={{ display: 'flex', alignItems: 'center' }}
                inertiaScrolling={true}
                inertiaScrollingSlowdown={0.75}
              />
            </Swipeable>
          </div>
        ) : (
          <div style={{ marginTop: '40px' }}>
            <h4 style={{ marginLeft: '50px', color: 'black' }}>{this.props.heading}</h4>

            <div className="lists-layout">
              <div className="lists-button">
                <IconButton
                  style={{ backgroundColor: '#dddddd' }}
                  aria-label="Expand"
                  onClick={this.TitlesClickLeftArrow}
                >
                  <ArrowLeftSVGF />
                </IconButton>
              </div>

              <div className="lists-scrollmenu">
                <ScrollMenu
                  ref={el => {
                    this.menu1 = el;
                  }}
                  translate={0}
                  data={movieData}
                  wheel={false}
                  dragging={false}
                  alignCenter={false}
                  innerWrapperStyle={{ display: 'flex', alignItems: 'center' }}
                  clickWhenDrag={false}
                />
              </div>

              <div className="button">
                {!(this.state.reachedLastTitle && this.state.lastItemVisible) && (
                  <IconButton
                    style={{ backgroundColor: '#dddddd' }}
                    aria-label="Expand"
                    onClick={this.TitlesClickRightArrow}
                  >
                    <ArrowRightSVGF />
                  </IconButton>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default WrapNetflixNew;
