/***********************************************************************
 *
 * DESCRIPTION :
 *   Show about screen to describe what 3Wise is
 *
 * PUBLIC FUNCTIONS :
 *
 * AUTHOR(S) :
 *   D.L. Johnson    START DATE : 1 Jan 2019
 *
 * Copyright 3Wise 2019. All rights reserved.
 ***********************************************************************/

import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';



import { unstable_Box as Box } from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import './About.css';
import { isMobile } from 'react-device-detect';

import * as common from '../../common.js';

import animate_owl from '../../assets/3wise_owl.gif';
import ReactGA from 'react-ga';
const growth_profanity = common.BASEURL + '/img/figures/' + 'profanity_grow.jpg';

const owlurl = common.BASEURL + '/img/icons/' + 'owl.png';
const swearGraph = common.BASEURL + '/img/large/' + 'profanity_stats.png';
const swearExample = common.BASEURL + '/img/large/' + 'blasphemy_example.jpg';


const styles = theme => ({
  container: {
    marginTop: '10px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginRight: '5%',
    marginLeft: '5%',
    maximumWidth: '600px',
    color: 'black',
  },

  container_mobile: {
    marginTop: '0px',
    display: 'flex',
    flexWrap: 'wrap',
    marginRight: '5%',
    marginLeft: '5%',
    color: 'black',
  },

  
  root: {
    width: '100%',
    zIndex: theme.zIndex.drawer + 1,
    background: '#eeeeee',
  },
});

class About extends React.Component {
  componentDidMount() {
    ReactGA.initialize('UA-140786114-1');
    ReactGA.pageview('/about');
  }

  owlIcons = (num, msg) => {
    const items = [];
    for (var i = 1; i <= num; i++) {
      items.push(<img className="owl" src={owlurl} alt={owlurl} />);
    }

    return (
      <div className="owlAbout">
        <div style={{ width: '170px' }}>{items}</div>
        <div style={{width: '100%'}}>
        <p >{msg}</p>
        </div>
      </div>
    );
  };

  render() {
    const { classes } = this.props;

    return (
      <div className="root">
         <Helmet>
        <title>About</title>
        </Helmet> 
        <div className={!isMobile ? 'root_inner' : 'root_inner_mobile'}>
          <div className={!isMobile ? classes.container : classes.container_mobile}>
            {/*<ReactPlayer url='http://localhost/video/3wise_owl.gif' playing />*/}
            {/*<div style={{textAlign: "center", width:"100%" }} >
        <img style={{maxWidth: "100%", maxHeight: "100%"}} src={animate_owl} alt="loading..." />
         </div>*/}
            <p style={{ width: '100%' }}>
              3Wise grew out of a personal frustration with the lack of information about the
              content of movies and TV series. There has been a growing trend in movies and TV
              series to use more swearing; blasphemy has espeically been increasing in the past few
              decades. To illustrate this, the following graph shows the increase in profanity in
              movies from 1911 to date.
            </p>

            <div style={{ width: '100%' }}>
              <img className="swearImage" src={swearGraph} alt={swearGraph} />
            </div>

            <p style={{ width: '100%' }}>
              {' '}
              Age ratings can't be relied on anymore. I have found many PG rated movies with plenty
              of blasphemy and profanity I wouldn't want to expose myself or my children to. There
              are many great movie information sites that will provide reviews and may warn you
              about some language use, but none tell you exactly which words or phrases are used and
              where they are located in the movie.
            </p>
            <p style={{ width: '100%' }}>
              3Wise tells you exactly where the swear words occur in the movie. It breaks up swear
              words into 4 categories: (i) Blasphemy, (ii) Profanity, (iii) Sexual words and (iv)
              Racial words. It also allows you to look at the phrase in the movie that uses the
              swear word to help you determine if this really is a swear word or not. This is
              especially useful for checking if God's name is being used in a blasphemous way or if
              somebody is, for example, praying to God. Below is an example of 3Wise exposing the
              blasphemy found in the PG-rated movie, "Back to the Future". {' '} 
            </p>
            <div style={{ width: '100%' }}>
              <img className="swearImage" src={swearExample} alt={swearExample} />
            </div>


            <p>
              {' '}
              Movies are rated between one and five owls. This gives an overall impression of the amount of swearing 
              in the movie or TV Series and is measured by the number of minutes for each swear word. In the case of 
              a TV Series, the average amount of swearing is calculated over all the episodes in that series. The meaning of 
              these own ratings is given below.{' '}
            </p>

            <fieldset className="owlSection">
              <legend> Owl rating system </legend>

              {this.owlIcons(1, 'Average of one swear word per minute')}
              {this.owlIcons(2, 'Average of one swear word every 5 minutes')}
              {this.owlIcons(3, 'Average of one swear word every 10 minutes')}
              {this.owlIcons(4, 'Average of one swear word every 20 minutes')}
              {this.owlIcons(5, 'Only one or two swear words and no blasphemy')}
            </fieldset>

            {/*<img style={{maxWidth: "100%"}} src={growth_profanity} alt="loading..." /> */}
          </div>
        </div>
      </div>
    );
  }
}

About.propTypes = {
  classes: PropTypes.object.isRequired,
};

const WrapAbout = withStyles(styles)(About);
export default WrapAbout;
