import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import * as common from '../../../common.js';
import { string } from 'prop-types';
import { connect } from 'react-redux';
import {
  setTVSeriesInfo,
  showMovieInfo,
  showTVInfo,
  setMovieID,
  setTVID,
  setMovieInfo,
  setMovieSQLInfo,
  setBlockedInfo,
  setCreditsInfo,
  setVideoInfo,
  addBookmarkTitle,
  delBookmarkTitle
} from '../../../redux/actions/index';

import { isMobile } from 'react-device-detect';
import SvgIcon from '@material-ui/core/SvgIcon';
import BlockedIcon from '@material-ui/icons/Block';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import VerifiedUser from '@material-ui/icons/VerifiedUser'


import Button from '@material-ui/core/Button';
import LoadingOverlay from 'react-loading-overlay';
import { green, white, blue} from '@material-ui/core/colors';

function TVIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M21 3H3c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h5v2h8v-2h5c1.1 0 1.99-.9 1.99-2L23 5c0-1.1-.9-2-2-2zm0 14H3V5h18v12z" />
      <text x="5" y="15" fontFamily="arial" fontSize="10px" fill="white">
        TV
      </text>
    </SvgIcon>
  );
}

function Cross(props) {
  return (
    <SvgIcon {...props}>
      <path d="M4.5,0v3H2v2h2.5v6h2V5H9V3H6.5V0H4.5z" fill="white"/>
    </SvgIcon>
  );
}

/*<svg version="1.1" id="religious-christian-11" xmlns="http://www.w3.org/2000/svg" width="11px" height="11px" viewBox="0 0 11 11">
  <path id="rect3338" d="M4.5,0v3H2v2h2.5v6h2V5H9V3H6.5V0H4.5z"/>
</svg>*/



class SingleMovie extends Component {
  state = {
    isHovering: false,
    clickCount: 0,
    loading: false,
  };

  handleEnter = () => {
    //console.log('Hovering')
    this.setState({ isHovering: true });
  };

  handleLeave = () => {
    this.setState({ isHovering: false });
  };

  fetchMovie = moviesql => {
    this.setState({ loading: true });
    // Convert to tt format IDMBID
    var movieID = moviesql.imdbID;
    if (movieID == 0) this.props.setMovieInfo({});
    else {
      var ttmovieID = 'tt';
      for (var i = 0; i < 7 - movieID.toString().length; i++) {
        ttmovieID = ttmovieID + '0';
      }
      ttmovieID = ttmovieID + movieID;
      //console.log("ttmovieID =", ttmovieID)

      const urlMovie = fetch(
        `https://api.themoviedb.org/3/movie/${ttmovieID}?api_key=17117ab9c18276d48d8634390c025df4&language=en-US`,
      );
      const urlCredits = fetch(`https://api.themoviedb.org/3/movie/${ttmovieID}/credits?api_key=17117ab9c18276d48d8634390c025df4
            `);
      const urlVideos = fetch(`https://api.themoviedb.org/3/movie/${ttmovieID}/videos?api_key=17117ab9c18276d48d8634390c025df4
            `);
      const urlBlocked = fetch(`${common.DJANGOURL}/api/findblocked/?type=movie&imdbid=${movieID}`);

      //console.log(urlMovie, urlCredits, urlVideos,urlBlocked)
      const urls = [urlMovie, urlCredits, urlVideos, urlBlocked];
      // const urls = [urlBlocked]
      Promise.all(urls)

        /* required for online */
        .then(([r1, r2, r3, r4]) => Promise.all([r1.json(), r2.json(), r3.json(), r4.json()]))
        /* required for online */
        .then(([data1, data2, data3, data4]) => {
          /* required for offline */
          //.then(([r4]) => Promise.all([r4.json()]))
          /* required for offline */
          //.then(([data4]) => {
          //console.log('SingleMovie, Setting Redux for movie data = ', data1)
          //console.log('SingleMovie, Setting Redux for Creits = ', data2)
          //console.log('SingleMovie, Setting Redux for Video = ',data3)
          //console.log('SingleMovie, Setting Redux for blocked = ', data4)

          /* required for online */
          this.props.setMovieInfo(data1);

          /* required for offline */
          //this.props.setMovieInfo({})

          /* required for online and offline */
          this.props.setMovieSQLInfo(moviesql);

          /* required for online */
          this.props.setCreditsInfo(data2);

          /* required for offline */
          //this.props.setCreditsInfo({});

          /* requied for online */
          this.props.setVideoInfo(data3.results);

          /* required for offline */
          //this.props.setVideoInfo({});

          /* required for online and offline */
          this.props.setBlockedInfo(data4);

          this.props.showMovieInfo();
          this.setState({ loading: false });
        })
        .catch(err => {
          //console.log(err)
          this.setState({ loading: false });
        });
    }
  };

  fetchTV = moviesql => {
    this.setState({ loading: true });
    // Convert to tt format IDMBID
    var TVID = moviesql.imdbID;
    var TMDBID = 0;
    if (TVID == 0) this.props.setTVInfo({});
    else {
      var ttTVID = 'tt';
      for (var i = 0; i < 7 - TVID.toString().length; i++) {
        ttTVID = ttTVID + '0';
      }

      ttTVID = ttTVID + TVID;
      //console.log("SingleMovie ttTVID =", ttTVID)

      fetch(
        `https://api.themoviedb.org/3/find/${ttTVID}?api_key=17117ab9c18276d48d8634390c025df4&language=en-US&external_source=imdb_id`,
      )
        .then(r => r.json())
        .then(data1 => {
          // Extract TV ID
          if ('tv_results' in data1) {
            this.props.setMovieInfo(data1.tv_results[0]);
            //console.log'SingleMovie, Setting Redux for TV MovieInfo = ', data1.tv_results)
            this.props.setMovieSQLInfo(moviesql);
            //console.log('SingleMovie, Setting Redux for TV MovieSQL = ', moviesql)

            // Select first record
            if (data1.tv_results.length > 0) {
              TMDBID = data1.tv_results[0].id;

              const urlCredits = fetch(`https://api.themoviedb.org/3/tv/${TMDBID}/credits?api_key=17117ab9c18276d48d8634390c025df4
              `);
              const urlVideos = fetch(`https://api.themoviedb.org/3/tv/${TMDBID}/videos?api_key=17117ab9c18276d48d8634390c025df4
              `);
              const urlSeriesEpisodes = fetch(`${common.DJANGOURL}/api/findtv/?id=${TVID}`);

              const urls = [urlCredits, urlVideos, urlSeriesEpisodes];
              Promise.all(urls)
                .then(([r1, r2, r3]) => Promise.all([r1.json(), r2.json(), r3.json()]))
                .then(([data1, data2, data3]) => {
                  //console.log'SingleMovie, Setting Redux for TV Credits = ', data1)
                  //console.log'SingleMovie, Setting Redux for TV Video = ',data2)
                  //console.log'SingleMovie, Setting Redux for Series , Episodes = ',data3)

                  this.props.setCreditsInfo(data1);
                  this.props.setVideoInfo(data2.results);
                  this.props.setTVSeriesInfo(data3);
                  this.props.showTVInfo();
                  this.setState({ loading: false });
                })
                .catch(err => {
                  //console.log(err)
                  this.setState({ loading: false });
                });
            }
          }
        })
        .catch(err => {
          //console.log(err)
          this.setState({ loading: false });
        });
    }
  };

  handleButtonShow = movieInfo => {
    var showScreen = false;
    if (isMobile) {
      //console.log('Got Mobile click in SingleMovie')
      if (this.state.clickCount == 0) this.setState({ clickCount: 1 });
      else {
        this.setState({ clickCount: 0 });
        showScreen = true;
      }
    } else {
      showScreen = true;
    }
    //console.log('SingleMovie: handlButtonShow', showScreen, this.state.clickCount, isMobile)
    if (showScreen) {
      // Check if this is a TV Series or Movie
      if (movieInfo.titleType == 'movie') {
        //console.log('Got Browser click in SingleMovie')
        //console.log('MovieID = ', movieInfo.imdbID);
        this.props.setMovieID(movieInfo.imdbID);
        this.fetchMovie(movieInfo);
      } else {
        //console.log('SingleMovie: Got TVSeries')
        //console.log('TVID = ', movieInfo.imdbID);
        this.props.setTVID(movieInfo.imdbID);
        this.fetchTV(movieInfo);
      }
    }
  };

  
  componentWillReceiveProps(nextProps) {
   //console.log('SingleMovie, ReceiveProps: ', nextProps)
  }

 

  render() {
    const { movie, netflix_region, currentUser} = this.props;

    //console.log('SingleMovie: loading ', this.state.loading);
    //console.log('SingleMovie: movie: ', movie)
    var stars = 0;
    if (movie.title_total_ratings_set[0]) stars = movie.title_total_ratings_set[0].rating;
    else {
      var fill = null;
      //console.log('singleMovie: no ratings: ', movie)
    }

    var title_category_ratings_set = movie.title_category_ratings_set;
    var movieyear = movie.startyear;
    var age_restriction_set = movie.age_rating_set;
    var netflix_set = movie.netflix_set;
    var titleType = movie.titleType;
    var movieFound = movie.found;
    var userType = Number(currentUser.type)
    var verifiedWords = movie.verifiedWords
    var christianMovie = movie.isChristian;

    //var age_restriction_country = 'US'
    var age_restriction_country_US = false;
    var age_restriction_country_GB = false;

    //var age_restriction_country_rating = '';
    var age_restriction_country_US_rating = '';
    var age_restriction_country_GB_rating = '';
    var netflix_available = false;
    var agerestrictionurl = common.BASEURL + '/img/icons/Ratings/GB/';

    age_restriction_set.forEach(function(element) {
      
      if (element['country'] == 'US') {
        age_restriction_country_US = true;
        age_restriction_country_US_rating = element['rating'];
      }
      if (element['country'] == 'GB') {
        age_restriction_country_GB = true;
        age_restriction_country_GB_rating = element['rating'];
      }

    });
    //console.log('Single Movie props Netflix ',netflix_region.toUpperCase(),netflix_set )
    netflix_set.forEach(function(element) {
      if (element['country'].toUpperCase() == netflix_region.toUpperCase()) 
        netflix_available = true
    });


    //console.log('SingleMovie verified Words: ', verifiedWords)

    var blasphemy_count = 0;
    var profanity_count = 0;
    var sexual_count = 0;
    var racial_count = 0;
    var blasphemy_rating = 0;
    var profanity_rating = 0;
    var sexual_rating = 0;
    var racial_rating = 0;

    //console.log('SingleMovie title_category_ratings_set: ', title_category_ratings_set)
    if (title_category_ratings_set.length > 0) {
      title_category_ratings_set.forEach(function(element) {
        if (element['category'] == 'Potential Blasphemy') {
          blasphemy_count = element['count'];
          blasphemy_rating = element['rating'];
        }
        if (element['category'] == 'Profanity') {
          profanity_count = element['count'];
          profanity_rating = element['rating'];
        }
        if (element['category'] == 'Sexual') {
          sexual_count = element['count'];
          sexual_rating = element['rating'];
        }
        if (element['category'] == 'Racial') {
          racial_count = element['count'];
          racial_rating = element['rating'];
        }
      });
    }

    var items = [];
    //const age = [];
    var age_GB = [];
    var age_US = [];
    var typeIcon = [];
    var wordCertified = []

    const blockedTitle = [];
    const netflixIcon = [];
    const bookmarkOverlay = [];
    const faithIcon = []

    const owlurl = common.BASEURL + '/img/icons/' + 'owl.png';
    const netflixurl = common.BASEURL + '/img/icons/' + 'netflix_icon.png';
    const scorchurl = common.BASEURL + '/img/icons/' + 'scorch.png';
    const halourl = common.BASEURL + '/img/icons/' + 'owl_halo.png';
    const imgurl = common.BASEURL + '/img/thumbs/w150/' + movie.poster;
    
   

    //const owltype = "owl1"

    const imgtext = movie.primaryTitle;

    /*if (stars < 1) {
      items.push(<img className="scorch" src={scorchurl} alt={scorchurl} />)
      
    }
    else if  (stars > 3) 
      items.push(<img className="halo" src={halourl} alt={icourl} />)
    else {
      for (var i=1; i<= stars; i++) {
  
        var icourl = owlurl
        if (i==1) {
            items.push(<img className="owl1" src={icourl} alt={icourl} />)
        }
        if (i==2) {
            items.push(<img className="owl2" src={icourl} alt={icourl} />)
        }
        if (i==3) {
            items.push(<img className="owl3" src={icourl} alt={icourl} />)
        }
        if (i==4) {
            items.push(<img className="owl4" src={icourl} alt={icourl} />)
        }
        if (i==5) {
            items.push(<img className="owl5" src={icourl} alt={icourl} />)
        }
      }
    } */

    // First move from 0-4 range to 1-5 range
    stars = stars + 1;

    for (var i = 1; i <= stars; i++) {
      var icourl = owlurl;
      if (i == 1) {
        items.push(<img className="owl1" src={icourl} alt={icourl} />);
      }
      if (i == 2) {
        items.push(<img className="owl2" src={icourl} alt={icourl} />);
      }
      if (i == 3) {
        items.push(<img className="owl3" src={icourl} alt={icourl} />);
      }
      if (i == 4) {
        items.push(<img className="owl4" src={icourl} alt={icourl} />);
      }
      if (i == 5) {
        items.push(<img className="owl5" src={icourl} alt={icourl} />);
      }
    }

    if (christianMovie) {
      faithIcon.push(<Cross className="faithicon" viewBox="0 0 12 12 " style={{ fontSize: 18}} />);
    }

    if (netflix_available) {
      netflixIcon.push(
        <img
          className="netflixIcon"
          src={netflixurl}
          alt={netflixurl}
        />,
      );
    }

    /*if (bookmark) {
      agerestrictionurl = netflixurl;
      bookmarkOverlay.push(
        <img
          className="netflixIcon"
          src={agerestrictionurl}
          alt={agerestrictionurl}
        />,
      );
    }*/
     


    if (age_restriction_country_GB) {
      agerestrictionurl = common.BASEURL + '/img/icons/Ratings/GB/'

      if (age_restriction_country_GB_rating == 'U') {
        age_GB.push(
          <img
            className="agerestrictionGB"
            src={agerestrictionurl + 'BBFC_U.svg.png'}
            alt={agerestrictionurl + 'U'}
          />,
        );
      }
      if (age_restriction_country_GB_rating == 'PG') {
        age_GB.push(
          <img
          className="agerestrictionGB"
            src={agerestrictionurl + 'BBFC_PG.svg.png'}
            alt={agerestrictionurl + 'PG'}
          />,
        );
      }
      if (age_restriction_country_GB_rating == '12A') {
        age_GB.push(
          <img
          className="agerestrictionGB"
            src={agerestrictionurl + 'BBFC_12A.svg.png'}
            alt={agerestrictionurl + '12A'}
          />,
        );
      }
      if (age_restriction_country_GB_rating == '12') {
        age_GB.push(
          <img
          className="agerestrictionGB"
            src={agerestrictionurl + 'BBFC_12.svg.png'}
            alt={agerestrictionurl + '12'}
          />,
        );
      }
      if (age_restriction_country_GB_rating == '15') {
        age_GB.push(
          <img
          className="agerestrictionGB"
            src={agerestrictionurl + 'BBFC_15.svg.png'}
            alt={agerestrictionurl + '15'}
          />,
        );
      }
      if (age_restriction_country_GB_rating == '18') {
        age_GB.push(
          <img
          className="agerestrictionGB"
            src={agerestrictionurl + 'BBFC_18.svg.png'}
            alt={agerestrictionurl + '18'}
          />,
        );
      }
      if (age_restriction_country_GB_rating == 'R18') {
        age_GB.push(
          <img
          className="agerestrictionGB"
            src={agerestrictionurl + 'BBFC_R18.svg.png'}
            alt={agerestrictionurl + 'R18'}
          />,
        );
      }
    }

    if (age_restriction_country_US)  {
      agerestrictionurl = common.BASEURL + '/img/icons/Ratings/US/'

      if (age_restriction_country_US_rating == 'G') {
        age_US.push(
          <img
            className="agerestrictionUS"
            src={agerestrictionurl + 'RATED_G.png'}
            alt={agerestrictionurl + 'G'}
          />,
        );
      }
      if (age_restriction_country_US_rating == 'PG') {
        age_US.push(
          <img
            className="agerestrictionUS"
            src={agerestrictionurl + 'RATED_PG.png'}
            alt={agerestrictionurl + 'PG'}
          />,
        );
      }
      if (age_restriction_country_US_rating == 'PG-13') {
        age_US.push(
          <img
          className="agerestrictionUS"
            src={agerestrictionurl + 'RATED_PG-13.png'}
            alt={agerestrictionurl + 'PG-13'}
          />,
        );
      }
      if (age_restriction_country_US_rating == 'NC-17') {
        age_US.push(
          <img
          className="agerestrictionUS"
            src={agerestrictionurl + 'NC-17.png'}
            alt={agerestrictionurl + 'NC-17'}
          />,
        );
      }
      if (age_restriction_country_US_rating == 'R') {
        age_US.push(
          <img
          className="agerestrictionUS"
            src={agerestrictionurl + 'RATED_R.png'}
            alt={agerestrictionurl + 'R'}
          />,
        );
      }

    }

    /*age.push (
      <div className="agerestriction-div">
        <b><font color="white"> {age_restriction_country} </font></b>
      </div>
    )*/



    if (titleType == 'tvSeries') {
      typeIcon.push(<TVIcon className="typeicon" viewBox="0 0 24 24 " style={{ fontSize: 24 }} />);
    }

    if (verifiedWords == 20) {
      wordCertified.push(<VerifiedUser className="certifiedicon" style={{ color: green[500],  fontSize: 22 }} />)
    }

    if (verifiedWords == 10) {
      wordCertified.push(<VerifiedUser className="certifiedicon" style={{ color: blue[500],  fontSize: 22 }} />)
    }



    if (!movieFound) {
      blockedTitle.push(<BlockedIcon className="blockedicon" style={{ fontSize: 100 }} />);
    }

    return (
      <LoadingOverlay active={this.state.loading} spinner>
        <div
          className={titleType == 'tvSeries' ? 'movie-card-tv' : 'movie-card'}
          onMouseEnter={this.handleEnter}
          onMouseLeave={this.handleLeave}
          onClick={() => this.handleButtonShow(movie)}
        >

          <img className="cardimg" src={imgurl} style={!movieFound ? {filter: 'grayscale(100%)'} : null} />

          
          {items}
          {netflixIcon}
          {}
          {/*age_GB*/}
          {age_US}
          {typeIcon}
          {blockedTitle}
          {wordCertified}
          {faithIcon}

          <div className="movie-title">
            <h4>{imgtext}</h4>
            <p>
              Release year: {movieyear}
              <br />
              Age restriction: {age_restriction_country_US_rating}
            </p>
            
              {titleType == 'tvSeries' ? (
                <div style={{ fontSize: 14 }}>
                  <b>Average over all episodes</b> <hr style={{ marginRight: '10px' }} />{' '}
                </div>
              ) : null}


              <b>Blasphemy</b>: {blasphemy_count} words
              <br />
              <b>Profanity</b>: {profanity_count} words
              <br />
              <b>Sexual</b>: {sexual_count} words
              <br />
              <b>Racial</b>: {racial_count} words
              <br />
              <Button
                onClick={() => this.handleButtonShow(movie)}
                color="primary"
                variant="contained"
                autoFocus
              >
                Details
              </Button>
           
          </div>
        </div>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = state => {
  return {
    bookmarks: state.movies_redux.bookmarks,
    currentUser: state.currentUser,
    
  };
};

const mapDispatchToProps = dispatch => {
  return {
    showMovieInfo: () => dispatch(showMovieInfo()),
    showTVInfo: () => dispatch(showTVInfo()),
    setMovieID: content => dispatch(setMovieID(content)),
    setTVID: content => dispatch(setTVID(content)),
    setMovieInfo: content => dispatch(setMovieInfo(content)),
    setMovieSQLInfo: content => dispatch(setMovieSQLInfo(content)),
    setCreditsInfo: content => dispatch(setCreditsInfo(content)),
    setVideoInfo: content => dispatch(setVideoInfo(content)),
    setTVSeriesInfo: content => dispatch(setTVSeriesInfo(content)),
    setBlockedInfo: content => dispatch(setBlockedInfo(content)),
    addBookmarkTitle: content => dispatch(addBookmarkTitle(content)),
    delBookmarkTitle: content => dispatch(delBookmarkTitle(content)),
  };
};

const WrapSingleMovie = connect(mapStateToProps, mapDispatchToProps)(SingleMovie);
export default WrapSingleMovie;
